$primary: #0E1117;
$secondary: #141D27;
$tertiary: #C9CFD4;

$success: #32B877;
$info: #FFEB3B;
$light: #C9CFD4;
$warning: #FF5722;
$danger: #C71A2F;

$dark: $primary;
$white: #E0E3E6;
$gray-text: #818F99;
$red: #C8102E;
$light-grey: #2B3847;
$dark-gray: #111921;
$gray: #1A2430;
$border-color: #3B4957;
$blue: #235281;
$hover-color: #1B334B; 


$font-family-base: 'Barlow';
$font-size-xl: 40px;
$font-size-l: 20px;
$font-size-m: 16px;
$font-size-s: 14px;
$font-size-xs: 12px;


$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1900px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1400px,
);

$link-color: #6F9ECD;;

$input-bg: $primary;
$input-color: $tertiary;
$input-border-color: $dark;
$input-focus-border-color: $secondary;
$input-height: 32px;
$input-padding-x: 1rem;

$mp-header-height: 50px;
$ds365-banner-height: 60px;
$ds365-container-height: 300px;
$ds365-zindex-fixed: 1030;
$ds365-zindex-overlay: 1051;
$ds365-zindex-top: 1050;
$marketplace-zindex-toast: 1200;

$header-height: 52px;