.modal-content {
    background: $secondary !important;
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.26) !important;
    border-radius: 10px !important;
}

.modal-content .modal-header .modal-title {
    @include label($font-size-l, 600, 140.625%, '', $white); // Assuming this mixin is defined elsewhere
}

.modal-content .modal-header .close {
    background: none !important;
    border: none !important;

    span {
        display: block;
        transform: scale(1.75);
    }
}

.modal-content .modal-header h4 {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: $font-size-m; // Using the variable for font size
    color: $white;
}

.modal-content .modal-header button.close span {
    color: $white;
}

.modal-content .modal-body .content-body {
    position: relative;
    min-height: 138px;
    @include label($font-size-l, 500, 140.625%, '', $light); 
}
