@import 'variables';

html {

  body {
    font-size: $font-size-m;

    // background: linear-gradient(87deg, $branding-color-1 0%, $branding-color-2 100%) !important;
    background: $dark !important;

    .page-title {
      margin-top: 70px !important;
      margin-bottom: 70px !important;
    }

    a {
      color: $link-color;
      text-decoration: none;
      transition: text-decoration 0.3s;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .nopadding {
    padding: 0 !important;
  }

  .cursor-default {
    cursor: default !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  $spaces: (
    10,
    15,
    20,
    25,
    30,
    40,
    50,
    60
  );

$directions: (
  top,
  bottom,
  left,
  right
);

@each $space in $spaces {
  .p-#{$space} {
    padding: #{$space} !important;
  }

  .m-#{$space} {
    margin: #{$space} !important;
  }

  .px-#{$space} {
    padding-right: #{$space}px !important;
    padding-left: #{$space}px !important;
  }

  .py-#{$space} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }

  .mx-#{$space} {
    margin-right: #{$space}px !important;
    margin-left: #{$space}px !important;
  }

  .my-#{$space} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }

  @each $direction in $directions {
    .m#{str-slice($direction, 0, 1)}-#{$space} {
      margin-#{$direction}: #{$space}px !important;
    }

    .p#{str-slice($direction, 0, 1)}-#{$space} {
      padding-#{$direction}: #{$space}px !important;
    }
  }
}
}

// label style
.label {
  font-family: $font-family-base;
  font-style: normal;
  font-size: $font-size-s;
  color: $light-grey;
}

/* divider */
.hr-solid {
  border-top: 1px solid $border-color;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
}

input[type=text] {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;

  width: 100%;
  height: 32px;

  background: $secondary;
  border: 1px solid $border-color;
  border-radius: 4px;

  flex: none;
  order: 1;
  flex-grow: 0;

  color: $tertiary !important;
  font-size: $font-size-m;
  font-family: $font-family-base;
  font-weight: 500;

}

.icon-placement {
  position: relative;
  left: 97%;
}

.icon-style {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  width: 14px;
  height: 20px;

  flex: none;
  order: 1;
  flex-grow: 0;
}

input[type="checkbox"] {
  appearance: none;
  width: 16px; /* Set the width to 16px */
  height: 16px; /* Set the height to 16px */
  border: 2px solid $tertiary; /* Border color for the checkbox */
  background-color: $secondary;
  margin-right: 3px;
  display: flex; /* Center the content vertically and horizontally */
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}

/* Create a white background using ::before pseudo-element */
input[type="checkbox"]::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: white; /* White background */
  position: absolute;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.2s ease-in-out;
}

/* Create the checkmark using ::after pseudo-element */
input[type="checkbox"]::after {
  content: "✔️"; /* Checkmark character */
  font-size: 12px; /* Adjust the size of the checkmark as needed */
  color: black; /* Color of the checkmark (black) */
  position: absolute;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.2s ease-in-out;
}

/* Show the white background and the checkmark when the checkbox is checked */
input[type="checkbox"]:checked::before,
input[type="checkbox"]:checked::after {
  opacity: 1; /* Fully visible */
}


// button

@mixin button($width: 112px, $height: 32px, $font-size: $font-size-xs, $background-color: $white, $font-color: $white, $font-weight: 'normal') {
  border-radius: 4px;
  color: $font-color;
  text-align: center;
  font-size: $font-size;
  font-family: $font-family-base;
  font-weight: $font-weight;
  width: $width;
  height: $height;
  background-color: $background-color;
}

@mixin label($font-size: $font-size-m, $font-weight: 500, $line-height: 140.62%, $text-transform: 'capitalize', $color: $tertiary) {
  font-weight: $font-weight;
  line-height: $line-height;
  text-transform: $text-transform;
  font-family: $font-family-base;
  font-size: $font-size;
  color: $color;
}

@mixin scrollbar($track-color: $primary, $thumb-color: $secondary) {

  /* width */
  ::-webkit-scrollbar {
    border: thin solid $secondary;
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $track-color;
    //border: thin solid $primary;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 4rem !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    border: thin solid $gray;
  }
}

.thin_arrows {
  width: 10px !important;
  height: 18px !important;

  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
}

.muted-label {
  color: $gray-text;
}

.btn-primary {
  @include button(112px, 32px, $font-size-s, $white, $primary, bold);
  border: none;
}
.btn-submit {
  @include button(auto, 32px, $font-size-s, $light-grey, $white, bold);
  border: none;
  cursor: pointer;
}
.back {
  @include label(10px, 700);
  color: $gray-text;
  cursor: pointer;
}

.disabled {
  opacity: 0.5;
}

select {
  box-sizing: border-box;
  width: 27%;
  height: 28px;
  background: $secondary;
  border: 1px solid $border-color;
  border-radius: 4px;
  color: $gray-text;
  font-size: $font-size-s;
}

.sort-select {
  margin: 1px;
  margin-right: 4px;
}

.select-lbl {
  @include label($font-size-s, 500);
  color: $gray-text;
  cursor: pointer !important;
}

input::placeholder {
  @include label($font-size-s, 500);
  color: $gray-text;
}

.tooltip .tooltip-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;

  width: 80%;
  height: fit-content;

  background: #2B3847;
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

::ng-deep .tooltip-inner {

  max-width: 80% !important;
  width: 80%;
  cursor: pointer;
}

.form-control  {
  background: $secondary;
  border: 1px solid $border-color;
  color: $gray-text;
}
.form-control:focus  {
  background: $secondary;
  border: 1px solid $border-color;
  color: $gray-text;
}
.btn-secondary {
  @include button(112px, 32px, $font-size-s, $light-grey, $white, bold);
  border: none;
  cursor: pointer;
}
.nav-title {
  @include label($font-size-xs, 700, 140.62%, capitalize);
}




